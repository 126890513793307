$primary-color: #ffc800;
$secondary-color-bg: #0c0c0c;
$primary-font: 'Gil sans regular';

/* Nav */
a {
  text-decoration: none;
}

ul {
  list-style: none;
}

header {
  width: 100%;
  position: relative;
  height: 120px;
  top: 0;
  left: 0;
  background-color: $secondary-color-bg;

  .container {
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding: 1rem 1.5rem;
    // height: 200px;
    overflow: visible;
  }
}


.menu-icons {
  color: #eee;
  font-size:4rem;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  z-index: 1500;
  display: none;
  
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  // border-bottom: 1px solid rgba(255,255,255,.1);
  a img {
    width: 200px;
  }
}

.logo {
  // background-color: $primary-color;
  display: flex;
  border-radius: 100%;
  margin-right: 5rem;
  height: 110%;
}

.logo i{
  margin: auto;
  font-size: 3rem;
  color: #eeee;
}

.nav-list {
  display:flex;
  width: 100%;
  justify-content: flex-end;
}

.nav-list li {
  line-height: 8rem;
  position: relative;
}

.sub-menu li {
  line-height: 4rem;
}

.nav-list a {
  display:block;
  color: #eee;
  
  padding: 0 1.5rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  transition: color 650ms;
}

.nav-list a i {
  margin-top: 20px;
}

.nav-list a:hover {
  color: $primary-color;
}

.sub-menu {
  width: 20rem;
  display: block;
  position: absolute;
  border-top: 3px solid $primary-color;
  background-color: $secondary-color-bg ;
  z-index: 100;
  margin-top: 2rem;
  transition: all 605ms ease;
  opacity: 0;
  visibility: hidden;
  // z-index: 1000;
}

.sub-menu::before{
  content: "";
  position: absolute;
  top: -2.5rem;
  left: 3rem;
  border: 1.2rem solid transparent;
  border-bottom-color: $primary-color;
  z-index: 1000;
}

.sub-menu .sub-menu::before{
  top: .9rem;
  left: -2.5rem;
  border: 1.2rem solid transparent;
  border-right-color: $primary-color;
}

.sub-menu .sub-menu{
  border-top: None;
  border-left:3px solid $primary-color;
  top:0;
  left: 160%;
}

.nav-list li:hover > .sub-menu {
  top: 8rem;
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

.sub-menu li:hover > .sub-menu {
  left: 100%;
  top: 0;
}

li.move-right {
  margin: auto 0 auto auto;
  line-height: initial;
}

.active-route {
  color:#ffc800 !important;
}

@media screen and (max-width: 1000px) {
  header {
    height: 150px;
    .container {
      height: 100%;
      width: 100%;
      nav {
        height: 100%;

        a img {
          height: 150px;
          width: auto;
          margin-left: -5rem;
        }
      }
    }
  }

  .nav-list{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    flex-direction: column;
    background-color: $secondary-color-bg;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    display: none;
    overflow-y: scroll;

    a {
      font-size: 5rem;
      text-align: center;
    }
  }

  .nav-list  li {
    line-height: 6rem;
    margin: 2rem auto;

    ul .sub-menu {
      li {
        margin: 4rem auto;
        line-height: 6rem;
      }
    }
  }
  .logo {
    // background-color: $primary-color;
    display: flex;
    border-radius: 100%;
    margin-right: 5rem;
    height: auto;
  }

  .sub-menu {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: initial;
    border: 3px solid transparent;
    border-left-color: $secondary-color-bg;
    margin-left: 0rem;
    margin: 2rem auto 0;
    max-height: 0;
    z-index: 1000;
  }

  .sub-menu::before {
    display: none;
  }

  .nav-list li:hover > .sub-menu{
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }

  li.move-right {
    margin: 0 auto 0 0;
    line-height: initial;
  }

  .menu-icons {
    display: block;
    font-size: 8rem;
    a {
      display: table;
      ion-icon {
        display:table-cell;
        vertical-align: middle;
      }
    }
  }

  .close-icon{
    display: none;
  }

  nav.active .close-icon {
    display: block;
  }

  nav.active .menu-icon {
    display: none;
  }

  nav.active .nav-list {
    display:flex;
  }
}

/* Nav */
