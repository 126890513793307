$primary-font: 'Gill Sans,Gill Sans MT,Calibri,sans-serif;';
$primary-font: 'Lato', sans-serif;
$primary-color: #ffc800;
$secondary-color: #0c0c0c;
// $primary-font: 'Gil sans regular';

*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: $primary-font !important;
}

html {
  // font-family: $primary-font !important;
  // font-size: 10px;
  font: normal 10px $primary-font;
  // overflow: scroll;
  margin: 0;
  padding: 0;
  max-height:100vh;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: $primary-font !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 1.5rem;
}




// Utilities



// Section A - Landing Page
.section-a {

  img {
    width: 900px;
  }
}

.section-a .container {
  padding: 4rem 2rem 4rem 2rem;
  width: 100%;
  background-size: cover;
  // background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-a h1 {
  font-size: 3rem;
  font-weight: 400;
  color: white;
  margin: 3rem 2rem;
  text-transform: uppercase;
}

.section-a p {
  font-size: 2rem;
  margin: 2rem 1rem 2rem 2rem;
}

.section-a a {
  color: white;
}

.landing-image {
  height: 100%;
}

.landing-text {
  background-color: rgba(25, 25, 25, .9);
  color: white;
  margin-left: -18rem;

  ul li {
    margin-left: 0rem;
  }
}

// Section B - Verbouwen in vertrouwen

.section-b {
  background-color: $secondary-color;
  // min-height: 500px;
}

.section-b .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 5rem 0rem;
}

.section-b h1 {
  font-size: 4rem;
  font-weight: 600;
  color: $primary-color;
  margin: 0rem 2rem;
  text-transform: uppercase;
}

.section-b p {
  font-size: 2rem;
  margin: 2rem 1rem 2rem 2rem;
}

.section-b-image {
  height: 350px;
  width: auto;
  border-radius: 2px;
  margin: 0rem 2rem 0rem 0rem;
}

.section-b .text-container {
  background-color: $secondary-color;
  color: white;
  // height: 250px;
  margin: 0rem 0rem 0rem 2rem;
  width: 700px;
}

.list-jobs {
  display: flex; 
  align-items: center; 
  justify-content: left;
  margin: 1rem;
}

.list-jobs i{
  color: $primary-color;
  margin-right: 0.5rem;
}


// Section C - Prijsofferte
.section-c {
  margin: 0;
  background-color: white;
}

.section-c .container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 1rem 2rem 3rem 2rem;
}

.section-c h1 {
  font-size: 4rem;
  font-weight: 600;
  color: $primary-color;
  margin: 3rem 2rem;
  text-transform: uppercase;
}

.section-c p {
  font-size: 2rem;
  margin: 2rem 1rem 2rem 2rem;
}

.section-c-image {
  height: 300px;
  width: auto;
  border-radius: 2px;
  margin: 2rem 0rem 0rem 0rem;
}

.section-c .text-container {
  background-color: white;
  color: $secondary-color;
  // height: 250px;
  // margin: 2rem -1rem 2rem 1rem;
  width: 700px;
  text-align: center;
}



// Section D - Contact
.section-contact {
  position: relative;
  padding: 4rem 2rem;
  min-height: 100vh;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: url()
  
  background-color: $primary-color;
}

.section-contact-page {
  background-size: cover;
  position: relative;
  // background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 92vh;  
  
  img {
    width: 100%;
  }

  .container {
    display: flex;
    text-align: center;
    flex-direction: column;
    
    h1 {
      font-size: 4rem;
      font-weight: 600;
      color: white;
    }

    p {
      font-size: 2rem;
      color: white;
    }
  }

  .container-contact {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
}

.section-contact .container {
  text-align: center;
}

.section-contact .container h1 {
  font-size: 4rem;
  font-weight: 600;
}

.section-contact .container p {
  font-size: 2rem;
}

.container-contact {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.contact-wrapper {
  max-width: 90rem;
  padding: 2rem;
  // background-color: $secondary-color;
  background: rgba(0, 0, 0, 0.9); 

  .contactInfo .box .text {
    justify-content: start;
    align-items: start;
    h3 {
      color: white;
    }
    p {
      color:white;
      
    }
  }
}

.container-contact .contactInfo {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container-contact .contactInfo .box {
  position: relative;
  padding: 2rem 0;
  display: flex;

  .icon {
    min-width: 6rem;
    height: 60px;
    background: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 25px;
  }

  .text {
    display: flex;
    margin-left: 2rem;
    margin-top: 0.75rem;
    font-size: 1.6rem;
    color: $secondary-color;
    flex-direction: column;
    font-weight: 300;
  }

  .h3{
    font-weight: 700;
    color: white;
  }
}

.contactForm {
  width: 40%;
  padding: 40px;
  background: #fff;
  font-family: $primary-font;

  .inputBox {
    position:relative;
    width: 100%;
    margin-top: 1rem;
    

    button {
      width: 100%;
      padding: 0.5rem 0;
      font-size: 1.6rem;
      // margin: 1rem 0;
      border: none;
      // border-bottom: 2px solid #333;
      outline: none;
      resize: vertical;
      cursor:pointer;
      font-family: $primary-font;
    }

    input,
    textarea {
      width: 100%;
      padding: 0.5rem 0;
      font-size: 1.6rem;
      margin: 1rem 0;
      border: none;
      border-bottom: 2px solid #333;
      outline: none;
      resize: vertical;
      font-family: $primary-font;
    }

    span {
      position: absolute;
      left: 0;
      padding: 0.5rem 0;
      font-size: 1.6rem;
      font-family: $primary-font;
      margin: 1rem 0;
    }
  }

  h2 {
    font-size: 2rem;
    color: #333;
    font-weight: 700;
  }
}

// Section E - Footer
.section-e {
  background-color: $secondary-color;
  position: relative;
  padding: 2rem 2rem;
  margin-bottom: 0rem;
  // height: 200px;
  bottom: 0;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem ;

    .footer-logo-container {
      .logo {
        display: flex;
        justify-content: left;
        align-items: center;
        img {
          width: 220px;
          margin-left: -7.5rem;
        }
        .text {
          font-size: 1.5rem;
          margin-left: -3rem;
          color: $primary-color;
          font-weight: 600;
        }
      }
      width: 50%;
      .text {
        color: white;
      }
    }

    .footer-contact-container {
      .text {
        p {
          margin: 1rem 0;
        }
        color: $primary-color;
        font-size: 1.5rem;
        span {
          
          margin: 2rem 0;
          i {
            position: relative;
            top: 1px;
          }
          
          a {
            color: $primary-color;
            font-weight: 800;
          }

        }
      }
    }
  }

  .footer-container {
    // display:flex;
    // flex-direction: column;
  }
}


// Diensten Header
.diensten-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 4rem;
    font-weight: 600;
    color: $primary-color;
    margin: 2rem 0 0.5rem 0;
    text-transform: uppercase;
    text-align:center;
  }

  p {
    font-size: 2rem;
    margin: 0.75rem 0;
    font-style: italic;
    text-align:center;
  }

  button {
    background-color: $primary-color;
    padding: 1rem;
    margin: 1rem 0 0 0;
    color: $secondary-color;
    border: solid 1px;
    border-radius: 6px;
    font-weight: 900;
    font-size: 1.5rem;
  }
}

// Cards
.cardswrapper {
  display: grid;
  place-items: center center;
  font-family: $primary-font;
  max-width: 1600px;
  margin: 0 auto;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  padding: 40px;
  grid-gap: 40px;
}


.card {
  background-color: $secondary-color;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(0,0,0,0.18);
  // max-width: 550px;
  img {
    width: 100%;
    
    height: 300px;
    display: block;
    border-radius: 20px 20px 0 0 ;
  }

  .card-container {
    position: relative;
    clip-path: polygon(0 0,100% 0, 100% 95%, 0 100%)
  }
}


.card-container:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px 20px 0 0;
  opacity: 0.3;
}

.card>.card-container:after {
  background-image: linear-gradient(
    135deg,
    #000000,
    white
  )
}


.details {
  padding: 20px 10px;

  h3 {
    color: $primary-color;
    font-weight: 200;
    font-size: 4rem;
    margin: 1rem 0 1rem 1rem;
  }

  p {
    color: white;
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    margin: 1rem 0 0rem 1rem;
  }
}


// Referentiewerken
.referentiewerken .container {
  width: 100%;
  max-width: 120rem;
  margin: auto;
  padding: 0 1.5rem;
}

.image-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  grid-gap: 2rem;
  grid-template-areas: 
        'img-1 img-1 img-5 img-3'
        'img-1 img-1 img-2 img-2'
        'img-4 img-4 img-4 img-4'
        'img-6 img-6 img-7 img-7'
        'img-8 img-8 img-7 img-7'
        'img-9 img-9 img-11 img-11'
        'img-9 img-9 img-11 img-11'
        'img-10 img-10 img-10 img-10'
        'img-12 img-12 img-13 img-13';

  img {
    
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-decoration: none;
    // filter: brightness(0.7) grayscale(0.7);
    transition: 0.3s ease-in-out;
    cursor: pointer;
    // pointer-events: none;
  }

  a:hover{
    // opacity: 1;
    // filter: brightness(1) grayscale(0);
    transform: scale(1.05);
  }

  .img-1 {
    grid-area: img-1;
    min-height: 52rem;
  }
  .img-2 {
    grid-area: img-2;
  }
  .img-3 {
    grid-area: img-3;
  }
  .img-4 {
    grid-area: img-4;
    min-height: 52rem;
  }
  .img-5 {
    grid-area: img-5;
  }
  .img-6 {
    grid-area: img-6;
  }
  .img-7 {
    grid-area: img-7;
    min-height: 52rem;
  }
  .img-8 {
    grid-area: img-8;
  }
  .img-9 {
    grid-area: img-9;
  }
  .img-10 {
    grid-area: img-10;
    min-height: 52rem;
  }
  .img-11 {
    grid-area: img-11;
    min-height: 52rem;
  }

  .img-12 {
    grid-area: img-12;
    min-height: 25rem;
  }

  .img-13 {
    grid-area: img-13;
  }
}


@media (max-width: 1200px) {
  .section-a .container {
    grid-template-columns: 1fr;
    text-align: center;
    display: block;
    width: 100%;
  }

  .section-a img {
    width: 100%;
    height: auto;
    margin: 2rem auto 0rem;
  }

  .landing-text {
    margin: 2rem auto 0rem;
    padding: 2rem;
    background-color: rgba(25, 25, 25, 1);
    vertical-align: middle;

    ul li p {
      text-align:left;
      padding-left: 3rem;
      i {
        margin-right: 2rem
      }
    }
  }

  .landing-text span {
    font-size: 2rem;
  }
}


@media (max-width: 850px) {
  // Landing Page

  

  // Section B
  .section-b .container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .section-b-image {
    width: 95%;
    height: auto;
    margin: 0rem 2rem 0rem 2rem !important;
  }

  .section-b .text-container {
    width: 100%;
    margin: 0rem auto 0rem;
  }


  // Section C
  .section-c .container {
    grid-template-columns: 1fr;
    text-align: center;
  }


  .section-c-image {
    width: 100%;
    height: auto;
    margin: 2rem auto 0rem;
  }

  .section-c .text-container {
    width: 100%;
    margin: 0rem auto 0rem;
  }


  // Section D - Contact
  .section-d .container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .section-d-image {
    width: 100%;
    height: auto;
    margin: 2rem auto 0rem;
  }

  .section-d .text-container {
    width: 100%;
    margin: 0rem auto 0rem;
  }


  // Diensten header
  .diensten-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    h1 {
      font-size: 4rem;
      font-weight: 600;
      color: $primary-color;
      margin: 2rem 0 0.5rem 0;
      text-transform: uppercase;
      text-align: center;
    }
  
    p {
      font-size: 2.5rem;
      margin: 0.75rem auto;
      font-style: italic;
      text-align: center;
    }
  
    button {
      background-color: $primary-color;
      padding: 1rem;
      margin: 1rem 0 0 0;
      color: $secondary-color;
      border: solid 1px;
      border-radius: 6px;
      font-weight: 900;
      font-size: 2rem;
    }
  }

  // Cards
  .cardswrapper {
    display: grid;
    place-items: center center;
    font-family: $primary-font;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .cards {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(460px, 1fr));
    padding: 40px;
    grid-gap: 40px;
  }
  
  
  .card {
    background-color: $secondary-color;
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(0,0,0,0.18);
    img {
      width: 100%;
      height: 400px;
      display: block;
      border-radius: 20px 20px 0 0 ;
    }
  
    .card-container {
      position: relative;
      clip-path: polygon(0 0,100% 0, 100% 95%, 0 100%)
    }
  }


  // Referentiewerken
  .image-gallery {
    display: block;
    padding: 2rem;
    margin: 2rem;

    a {
      display:block;
      // height: 20rem;
      filter: brightness(1) grayscale(0);
      margin: 2rem 0;
      background-size: cover;
      object-fit: cover;
      pointer-events: none;
    }

    .img-1 {
      min-height: 60rem;
    }
    .img-2 {
      min-height: 30rem;
    }
    .img-3 {
      min-height: 40rem;
    }
    .img-4 {
      min-height: 28rem;
    }
    .img-5 {
      min-height: 50rem;
    }
    .img-6 {
      min-height: 26rem;
    }
    .img-7 {
      min-height: 45rem;
    }
    .img-8 {
      min-height: 30rem;
    }
    .img-9 {
      min-height: 51rem;
    }
    .img-10 {
      min-height: 28rem;
    }
    .img-11 {
      min-height: 65rem;
    }
    .img-12 {
      min-height: 30rem;
    }
    .img-13 {
      min-height: 30rem;
    }
  }

  .section-contact-page {
    .contact-wrapper {
      margin: -40rem 2rem 0;
    }
  }
}




@media (max-width: 1000px) {
  .cards {
    grid-template-columns: repeat(1, 650px);
    // display: flex;
  }
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* iPad Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* CSS */
  .card {
    img {
      width: 100%;
      height: 300px;
    }
  }

  .image-gallery {
    grid-gap: 3rem;
    a {
      filter: brightness(1) grayscale(0);
    }
  }
    

}



/* iPad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
/* CSS */

  .card img {
    width: 100%;
    height: 400px;
  }
}